import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import './App.css';

// 컴포넌트들을 lazy 로드로 변경
const ActorDetail = lazy(() => import('./pages/ActorDetail'));
const ActorRegistration = lazy(() => import('./components/ActorRegistration'));
const ActorList = lazy(() => import('./pages/ActorList'));
const ActorEdit = lazy(() => import('./pages/ActorEdit'));
const GeneratePDF = lazy(() => import('./pages/GeneratePDF'));
const Notice = lazy(() => import('./components/ToidNotice'));
const Admin = lazy(() => import('./pages/Admin'));
const Aipage = lazy(() => import('./components/Aipage'));
const MobileMenu = lazy(() => import('./components/MobileMenu'));
const Hiring = lazy(() => import('./components/Hiring'));
const HiringDetail = lazy(() => import('./components/HiringDetail'));
const Imsi = lazy(() => import('./components/Imsi'));

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={
              <>
                <Header />
                <SearchBar />
              </>
            } />
            <Route path="/register" element={<><Header /><ActorRegistration /></>} />
            <Route path="/actorList" element={<><Header /><ActorList /></>} />
            <Route path="/detail/:id" element={<><Header /><ActorDetail /></>} />
            <Route path="/actorEdit/:id" element={<><Header /><ActorEdit /></>} />
            <Route path="/notice" element={<><Header /><Notice /></>} />
            <Route path="/generatePDF/:id" element={<><Header /><GeneratePDF /></>} />
            <Route path="/admin" element={<Admin />} /> {/* Admin 페이지에서는 Header 제외 */}
            <Route path="/toidai" element={<><Header /><Aipage /></>} />
            <Route path="/menu" element={<MobileMenu />} />
            <Route path="/hiring" element={<><Header/><Hiring /></>} /> 
            <Route path="/hiringdetail/:id" element={<><Header/><HiringDetail /></>} /> 
            <Route path="/imsi" element={<><Header/><Imsi /></>} /> 
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
