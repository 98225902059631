import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import { fetchActorsAndModels } from '../utils/api';
import './SearchBar.css';
import { supabase } from '../supabaseClient';
import { Link } from 'react-router-dom';

function SearchBar() {
  const [actors, setActors] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [coverImages, setCoverImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const sampleActorIds = [84, 109, 96, 94, 102, 106, 101, 97, 99, 105];

  const images = [
    '/img/findout_sample.png',
    '/img/profile_web_sample.png',
    '/img/profile_pdf_sample.png',
    '/img/ai_sample.png'
  ];

  const texts = [
    '- 키워드를 통한 필터 기능 -',
    '- 나만의 웹 프로필 제공 -',
    '- 프로필 PDF파일 자동 완성 기능 -',
    '- AI를 통한 배우 추천 기능 -',
  ];

  useEffect(() => {
    const fetchCoverImages = async () => {
      setIsLoading(true);
      try {
        const fetchedImages = [];

        for (const id of sampleActorIds) {
          const { data: imagesData, error: imagesError } = await supabase
            .from('actor_images')
            .select('actor_id, url')
            .eq('actor_id', id)
            .eq('type', 'cover')
            .maybeSingle();

          if (imagesError) {
            // console.error('Error fetching cover image:', imagesError.message);
            continue;
          }

          if (imagesData) {
            fetchedImages.push(imagesData);
          }
        }

        setCoverImages(fetchedImages);
      } catch (error) {
        // console.error('Error fetching cover images:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoverImages();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchActorsAndModels();

      const processedData = data.map(actor => ({
        ...actor,
        styleKeywords: Array.isArray(actor.styleKeywords) ? actor.styleKeywords : JSON.parse(actor.styleKeywords || '[]'),
        sports: Array.isArray(actor.sports) ? actor.sports : JSON.parse(actor.sports || '[]'),
        dialects: Array.isArray(actor.dialects) ? actor.dialects : JSON.parse(actor.dialects || '[]'),
        talents: Array.isArray(actor.talents) ? actor.talents : JSON.parse(actor.talents || '[]'),
      }));

      setActors(processedData);
    };

    fetchData();
  }, []);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextSlide();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="main-space">
      <div className="main-black-g">
        <h1 className="main-title-am">배우&모델<br></br></h1>
        <div className="main-title">프로필 관리·탐색 플랫폼
          <div className="key-cursor" />
        </div>
        <div className="slider-group">
          <div className="slider-container">
            <div className="slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
              {images.map((image, index) => (
                <div className="slide" key={index}>
                  <img className="main-img" src={image} alt={`슬라이드 이미지 ${index}`} />
                  <div className="main-content-text">{texts[index]}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Link to="/register">
          <button className="main-register-button">배우 등록하기</button>
        </Link>
        <Link to="/actorList">
          <button className="main-actorlist-button">배우&모델 찾기</button>
        </Link>
      </div>
      <div className="main-white">
        <div className="cover-slider">
          <div className="cover-slider-track">
            {[...coverImages, ...coverImages, ...coverImages].map((image, index) => (
              <Link to={`/detail/${image.actor_id}`} key={`${image.actor_id}-${index}`}>
                <div className="cover-image-container">
                  <img
                    className="cover-image"
                    src={image.url}
                    alt={`배우 ${image.actor_id}`}
                    onError={(e) => {
                      // console.error(`Failed to load image: ${image.url}`);
                      e.target.style.display = 'none';
                      e.target.nextSibling.style.display = 'flex';
                    }}
                  />
                  <div className="cover-image-fallback">
                    배우 {image.actor_id}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="main-real-white-g"></div>
      <div className="main-real-white">
        <div className="main-actor-model">배우&모델</div>
        <h1 className="main-amt">"작품과 연습에만 집중하고 싶으신가요?"</h1>
        <h1 className="main-amt-m">"작품과 연습에만<br></br>집중하고 싶으신가요?"</h1>
        <div className="main-amt-line"></div>
              <div className="main-amt-content">이제
              <strong> 한 번의 등록</strong>으로 캐스팅을 기다리세요!</div>
              <div className="main-amt-content-m">이제
              <strong> 한 번의 등록</strong>으로<br></br>캐스팅을 기다리세요!</div>

              <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">1.</span> 구분 <span className="filsu">*필수</span></label>
            </div>
            <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">2.</span> 이름(또는 활동명) <span className="filsu">*필수</span></label>
            </div>
            <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">16.</span> 스타일 키워드 (복수 선택 - 최대 10개) <span className="filsu">*필수</span></label>
            </div>
            <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">17.</span> 구사언어 (복수 선택 가능) <span className="filsu">*필수</span></label>
            </div>
            <div className="main-form-group">
            <label className="main-form-label"><span className="label-number">18.</span> 사투리 (복수 선택 가능) <span className="filsu">*필수</span></label>
            </div>
            <div className="main-real-white-g"></div>
            <div style={{color:'#000000', fontSize: '30px', fontWeight: '900', lineHeight: '100%'}}>.<br></br>.<br></br>.</div>
            <div className="main-amt-content">
              배우 등록이 <strong style={{fontSize: '28px'}}>완료</strong> 되었다면?
            </div>
            <div>
              <div className="main-keyword-style">귀여운</div>
              <div className="main-keyword-style">강아지상</div>
              <div className="main-keyword-style">청순한</div>
              <div className="main-keyword-style">평온한</div>
              <div className="main-keyword-style">부드러운</div>
              <div className="main-keyword-style">사랑스러운</div>
            </div>
            <div>
              <div className="main-keyword-lang">한국어</div>
              <div className="main-keyword-lang">영어</div>
              <div className="main-keyword-lang">일본어</div>
              <div className="main-keyword-lang">스페인어</div>
            </div>
            <div>
              <div className="main-keyword-satu">충청도사투리</div>
              <div className="main-keyword-satu">전라도사투리</div>
              <div className="main-keyword-satu">경상도사투리</div>
              <div className="main-keyword-satu">강원도사투리</div>
            </div>
            <div>
              <div className="main-keyword-talent">노래</div>
              <div className="main-keyword-talent">피아노</div>
              <div className="main-keyword-talent">바이올린</div>
            </div>
            <div>
              <div className="main-keyword-sports">수영</div>
              <div className="main-keyword-sports">요가</div>
              <div className="main-keyword-sports">골프</div>
              <div className="main-keyword-sports">헬스</div>
            </div>

            <div style={{color:'black', fontSize: '30px', fontWeight: '900', marginTop: '20px'}}>키워드 기반으로 웹 프로필 생성!</div>
            <div className="main-amt-img2">
            <img className="main-amt-img-rc" src="/img/profile_web_sample.png" alt="ample" />
            <img className="main-amt-img-rc" src="/img/profile_web_sample2.png" alt="ample" />
            </div>
            <img src="/img/share-big.png" alt="share-big" style={{width: '60px', marginTop: '40px'}}/>
            <div className="main-url-i">http://toid.kr/detail/...
              <img src="/img/link.png" alt="link" style={{width: '15px'}}/>
            </div>
            <div className="main-amt-content">링크 공유를 통해
            <strong style={{fontSize: '28px', fontWeight:'900'}}> 쉽게 프로필을 전송</strong>해 보세요!</div>

            <div className="main-amt-content-m">링크 공유를 통해<br></br>
            <strong style={{fontSize: '28px', fontWeight:'900'}}> 쉽게 프로필을 전송</strong>해 보세요!</div>

            <div style={{backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))', height: '300px', width: '100%', marginTop: '50px'}}></div>
              <div style={{backgroundColor: '#000000', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px'}}>
              <div style={{color:'#ffffff', fontSize:'34px', marginTop: '0px'}}><strong>출력 파일</strong>이 필요하시다고요?</div>
              </div>
            <div style={{backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))', height: '300px', width: '100%'}}></div>


              <img src="/img/pdf-big.png" alt="share-big" style={{width: '60px', marginTop: '70px'}}/>
              <div style={{color:'black', fontSize: '28px', fontWeight: '500', marginTop: '20px', marginBottom: '30px'}}>
                PDF 버튼을 눌러서 <strong style={{fontSize: '28px', fontWeight:'900'}}> 출력</strong>해보세요!
              </div>
              <div className="main-amt-img2">
            <img className="main-amt-img-rc2" src="/img/profile_pdf_sample2.png" alt="ample" />
            <img className="main-amt-img-rc2" src="/img/profile_pdf_sample.png" alt="ample" />
            </div>
      </div>
      <div className="main-real-white-g-re"></div>
      <div className="main-staff-info">
        <div className="main-staff-i">캐스팅 담당자</div>
        <h1 className="main-staff">"작품에 알맞는 배우를 찾고 싶으신가요?"</h1>
        <h1 className="main-staff-m">"작품에 알맞는<br></br>배우를 찾고 싶으신가요?"</h1>
        <div className="main-staff-line"></div>
      </div>

      <div className="footer">
        <div className="footer-bar"></div>
        토이드(TOID) | 충남 아산시 순천향로 22 미디어랩스관 702호 <br></br>
        대표 : 양승철 | 문의 : toidfilm@gmail.com
        <p>© 2024. TOID Co. all rights reserved.</p>
      </div>
    </div>
  );
}

export default SearchBar;
